import {
  FaArrowRight,
  FaEnvelope,
  FaFilter,
  FaPhone,
  FaTimes,
} from "react-icons/fa";
import React, { useEffect, useMemo, useState } from "react";

import { FaMapMarkerAlt } from "react-icons/fa";
import GoogleMapReact from "google-map-react";
import ErfMarker from "./map/ErfMarker";
import SearchLocationInput from "./SearchLocationInput";
import Geocode from "react-geocode";
import ErfMapContainer from "./map/ErfMapContainer";
import ErfLaboSnippet from "./map/ErfLaboSnippet";
import ErfCarteWoosMaps from "./map/ErfCarteWoosMaps";

export default function ErfCarte({
  gkey,
  langue,
  labData,
  fert,
  laboId = 0,
  mode = "full",
  centerString,
}) {
  const fertilite = fert === "true" ? true : false;

  const [position, setPosition] = useState(
    // fertilite ? { lat: 46.227638, lng: 2.213749 } : { lat: 30, lng: -15 }
    { lat: 30, lng: -15 }
  );
  const [markers, setMarkers] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [userPosition, setUserPosition] = useState({ lat: 0, lng: 0 });
  const [theGoogleRef, setTheGoogleRef] = useState(null);
  const [bounds, setBounds] = useState([-180, -90, 180, 90]);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (centerString) {
      const [lat, lng] = centerString.split(",");
      setCenter({ lat: parseFloat(lat), lng: parseFloat(lng) });
      setPosition({ lat: parseFloat(lat), lng: parseFloat(lng) });
    } else {
      setCenter(position);
    }
  }, [centerString]);

  // const [zoom, setZoom] = useState(fertilite ? 4 : 10);
  const [zoom, setZoom] = useState(mode == "full" ? 4 : 10);
  const [mapZoom, setMapZoom] = useState(10);
  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    // Fuseau horaire actuel:
    const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isEurope = currentZone.indexOf("Europe") > -1;

    const isGuyanne = currentZone == "America/Cayenne";

    const isMartinique = currentZone.indexOf("Martinique") > -1;

    // Around - 5 hours, so - 300 minutes

    if (isEurope) {
      setPosition({ lat: 46.7540333, lng: 1.0273021 });
      setZoom(6);
    }

    if (isGuyanne) {
      setPosition({ lat: 5.1967405, lng: -53.8595998 });
      setZoom(9);
    }

    if (isMartinique) {
      setPosition({ lat: 14.6152633, lng: -61.1534901 });
      setZoom(10.5);
    }
  }, []);

  Geocode.setApiKey(gkey);

  // set response language. Defaults to english.
  Geocode.setLanguage(langue);

  const localiseMe = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      const rand = Math.random() * (0.01 - 0.001) + 0.001;
      setPosition({
        lat: position.coords.latitude + rand,
        lng: position.coords.longitude + rand,
      });
      // googleMapRef.setZoom(12);
      setZoom(12);
    });
  };

  useEffect(() => {
    console.log({ mode });
    if (!dataLoaded) return;
    if (laboId > 0) {
      const selectedLab = markers.find(
        (marker) =>
          +marker.Latitude === center.lat && +marker.Longitude === center.lng
      );

      if (selectedLab) {
        setSelectedMarker(selectedLab);
        setPosition({
          lat: parseFloat(selectedLab.Latitude),
          lng: parseFloat(selectedLab.Longitude),
        });
        setZoom(13);
      }
    }
  }, [laboId, dataLoaded]);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetch(
        `/umbraco/surface/locator/GetLabListToDisplay?fertilite=${
          fertilite ? "true" : "false"
        }&lang=${langue}`
      );
      console.log(
        `/umbraco/surface/locator/GetLabListToDisplay?fertilite=${
          fertilite ? "true" : "false"
        }&lang=${langue}`
      );
      const data = await response.json();
      // const data = {
      //   success: true,
      //   data: JSON.parse(labData)
      // } ;
      if (data?.success) {
        let items = data.data;
        if (langue === "en") {
          items.forEach((item) => {
            item.Url = item.Url.replace(
              "/fr/nos-laboratoires",
              "/en/find-a-lab"
            );
          });
        }
        setMarkers(data.data);
        setDataLoaded(true);
      }
    };
    loadData();
    // }, []);
  }, [labData]);

  useEffect(() => {
    if (!theGoogleRef) {
      return;
    }
  }, [markers]);
  // Translate it to GeoJSON format

  const [filterCovid, setFilterCovid] = useState(false);
  const [filterPma, setFilterPma] = useState(false);
  const [filterPrelevement, setFilterPrelevement] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterRdv, setFilterRdv] = useState(false);

  const strings = {
    indiquer_lieu: {
      fr: "Indiquer un lieu",
      en: "Specify a location",
    },
    filtrer: {
      fr: "Filtrer",
      en: "Filter",
    },
    me_localiser: {
      fr: "Me localiser",
      en: "Localise me",
    },
    depistage_covid: {
      fr: "Depistage Covid-19",
      en: "Covid-19 diagnosis",
    },
    pma: {
      fr: "Fertilité",
      en: "ART",
    },
    prelevement: {
      fr: "Prélèvement à domicile",
      en: "Sample collection at home",
    },
    rendez_vous: {
      fr: "Rendez-vous en ligne",
      en: "Online appointment",
    },
    actuellement_ouvert: {
      fr: "Actuellement ouvert",
      en: "Currently open",
    },
    horaires_douverture: {
      fr: "Horaires d'ouverture",
      en: "Opening hours",
    },
    horaire_douverture_et_informations: {
      fr: "Plus d'infos",
      en: "More info",
    },
    itineraires: {
      fr: "Itinéraires",
      en: "Directions",
    },
    title: {
      fr: "Rechercher un laboratoire Eurofins Biologie Médicale",
      en: "Search for a Eurofins Biologie Médicale Lab",
    },
    lienRDV: {
      fr: "Rendez-vous",
      en: "Appointment",
    },
    voirResultats: {
      fr: "Voir mes résultats",
      en: "View my results",
    },
    titreExceptionnel: {
      fr: "Horaire exceptionnel",
      en: "Exceptional schedule",
    },
    ouvert: {
      fr: "Ouvert maintenant",
      en: "Open now",
    },
    ferme: {
      fr: "Fermé actuellement",
      en: "Closed now",
    },
    urlResultats: {
      fr: "/fr/resultat-analyse",
      en: "/en/results",
    },
  };
  const points =
    markers &&
    markers.length > 0 &&
    dataLoaded &&
    markers
      .filter((marker) => {
        if (filterCovid) {
          return marker.Covid === true;
        }
        if (filterOpen) {
          return marker.IsOpen === true;
        }
        if (filterRdv) {
          return marker.PriseRdv === true;
        }
        if (filterPma || fertilite) {
          return marker.PMA === true;
        }
        if (filterPrelevement) {
          return marker.PrelevementDomicile === true;
        }
        return true;
      })
      .map((marker) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(marker.Longitude),
              parseFloat(marker.Latitude),
            ],
          },
          properties: {
            cluster: false,
            nom: marker.NomLabo,
            item: marker,
          },
        };
      });

  const searchGeometry = (geometry, zoom) => {
    console.log({ lat: geometry.location.lat, lng: geometry.location.lng });
    setSelectedMarker(null);
    setPosition({
      lat: geometry.location.lat,
      lng: geometry.location.lng,
    });
    setZoom(zoom);
  };

  const onChange = (value) => {
    setSelectedMarker(null);
    searchGeometry(value, 13);
  };

  const titreExceptionnel = strings.titreExceptionnel[langue];

  const horairesX = selectedMarker && selectedMarker.Horaires[0].Horaire;

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d;
  };

  const filteredLabs = useMemo(() => {
    if (!dataLoaded || !markers) return [];

    console.log("filtering in process. Total before filter: ", markers.length);

    const newMarkers = markers.filter((marker) => {
      if (filterCovid) {
        return marker.Covid === true;
      }
      if (filterOpen) {
        return marker.IsOpen === true;
      }
      if (filterRdv) {
        return marker.PriseRdv === true;
      }
      if (filterPma || fertilite) {
        return marker.PMA === true;
      }
      if (filterPrelevement) {
        return marker.PrelevementDomicile === true;
      }
      return true;
    });

    console.log("Total after filter: ", newMarkers.length);

    return newMarkers;
  }, [
    filterCovid,
    filterOpen,
    filterRdv,
    filterPma,
    filterPrelevement,
    fertilite,
    markers,
    dataLoaded,
  ]);

  const getVisibleLabsSortedByDistance = () => {
    if (!bounds || !center) return [];

    // If zoom is continent-wide, don't return any markers
    if (mapZoom < 5) return [];

    const visibleLabs = filteredLabs.filter((marker) => {
      if (!marker.Latitude || !marker.Longitude) return false;
      const lat = parseFloat(marker.Latitude);
      const lng = parseFloat(marker.Longitude);

      return (
        lat >= bounds[1] &&
        lat <= bounds[3] &&
        lng >= bounds[0] &&
        lng <= bounds[2]
      );
    });

    const currentCenter = center || position;

    visibleLabs.sort((a, b) => {
      const aDist = calculateDistance(
        currentCenter.lat,
        currentCenter.lng,
        parseFloat(a.Latitude),
        parseFloat(a.Longitude)
      );
      const bDist = calculateDistance(
        currentCenter.lat,
        currentCenter.lng,
        parseFloat(b.Latitude),
        parseFloat(b.Longitude)
      );

      return aDist - bDist;
    });

    return visibleLabs;
  };

  const visibleLabs = useMemo(() => getVisibleLabsSortedByDistance(), [
    bounds,
    center,
    filteredLabs,
  ]);

  return (
    <div className="themap relative">
      {mode == "full" && (
        <header className="degrade p-4 py-8 text-white">
          <div className="container mx-auto">
            <h3 className="text-white">{strings.title[langue]}</h3>
            <SearchLocationInput
              className="my-4 w-full max-w-[600px] rounded-sm px-4 py-2 text-black"
              placeholder={strings.indiquer_lieu[langue]}
              onChange={(value) => onChange(value)}
            />
            <div className="mt-4">
              {!fertilite && (
                <button
                  className="mapbutton inline-block rounded-sm bg-white px-4 py-2 font-arial text-sm text-blue-500"
                  onClick={() => {
                    setFiltersOpen(!filtersOpen);
                  }}
                >
                  {strings.filtrer[langue]} <FaFilter />
                </button>
              )}
              <button
                className={`mapbutton ${
                  fertilite ? "" : "ml-4"
                } inline-block bg-white rounded-sm text-blue-500 px-4 py-2 font-arial text-sm`}
                onClick={() => localiseMe()}
              >
                <FaMapMarkerAlt />
                {strings.me_localiser[langue]}
              </button>
            </div>
            {filtersOpen && !fertilite && (
              <div className="mt-4 grid grid-cols-4 lg:w-1/2">
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={(e) => {
                      setFilterCovid(e.target.checked);
                    }}
                  />
                  {strings.depistage_covid[langue]}
                </label>
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={(e) => {
                      setFilterPma(e.target.checked);
                    }}
                  />
                  {strings.pma[langue]}
                </label>
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={(e) => {
                      setFilterOpen(e.target.checked);
                    }}
                  />
                  {strings.actuellement_ouvert[langue]}
                </label>
                <label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={(e) => {
                      setFilterPrelevement(e.target.checked);
                    }}
                  />
                  {strings.prelevement[langue]}
                </label>
              </div>
            )}
          </div>
        </header>
      )}

      <ErfMapContainer
        labosVisibles={visibleLabs}
        strings={strings}
        lang={langue}
        right={
          <>
            <ErfCarteWoosMaps
              position={position}
              markers={filteredLabs}
              zoom={zoom}
              selectedMarker={selectedMarker}
              center={center}
              setSelectedMarker={setSelectedMarker}
              onMapChange={({ lat, lng, zoom, center, bounds }) => {
                const theBounds = [
                  bounds._lngRange.min,
                  bounds._latRange.min,
                  bounds._lngRange.max,
                  bounds._latRange.max,
                ];
                setBounds(theBounds);
                setMapZoom(zoom);
              }}
            />
            {selectedMarker && (
              <div className="absolute right-24 top-16 bottom-auto rounded-lg bg-white p-4 text-blue-500 lg:top-auto lg:bottom-16">
                <div className="absolute top-0 right-0 z-50">
                  <button
                    className="mapbutton inline-block rounded-full px-4 py-2 font-arial text-sm text-blue-500"
                    onClick={() => {
                      setSelectedMarker(null);
                    }}
                  >
                    <FaTimes />
                  </button>
                </div>
                <ErfLaboSnippet
                  labo={selectedMarker}
                  strings={strings}
                  small={true}
                  lang={langue}
                />
              </div>
            )}
          </>
        }
      />

      {false && (
        <div className="absolute right-24 bottom-16 rounded-lg bg-white p-4 text-blue-500">
          <div className="absolute top-0 right-0 z-50">
            <button
              className="mapbutton inline-block rounded-full px-4 py-2 font-arial text-sm text-blue-500"
              onClick={() => {
                setSelectedMarker(null);
              }}
            >
              <FaTimes />
            </button>
          </div>
          <h3 className="">
            <span>{selectedMarker.NomLabo}</span>
          </h3>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.Adresse}`}
            target="_blank"
            className="mt-4 text-sm"
          >
            <FaMapMarkerAlt className="inline text-[25px]" />{" "}
            {selectedMarker.Adresse}
          </a>
          {selectedMarker.Telephone && (
            <div className="mt-4 text-sm">
              <FaPhone className="inline text-[25px]" />{" "}
              {selectedMarker.Telephone}
            </div>
          )}
          {selectedMarker?.Email && (
            <a
              href={`mailto: ${selectedMarker.Email}`}
              className="mt-4 block text-sm"
            >
              <FaEnvelope className="inline text-[25px]" />{" "}
              {selectedMarker.Email}
            </a>
          )}

          <div className="mt-4">
            <h4>{strings.horaires_douverture[langue]}</h4>
            <ul>
              {!selectedMarker.HorairesExceptionnels &&
                selectedMarker.Horaires.map((h, index) => {
                  const currentDay =
                    langue == "fr"
                      ? new Date().toLocaleDateString("fr-FR", {
                          weekday: "long",
                        })
                      : new Date().toLocaleDateString("en-US", {
                          weekday: "long",
                        });
                  const frToEnDays = {
                    Lundi: "Monday",
                    Mardi: "Tuesday",
                    Mercredi: "Wednesday",
                    Jeudi: "Thursday",
                    Vendredi: "Friday",
                    Samedi: "Saturday",
                    Dimanche: "Sunday",
                  };
                  const enFerme = "Closed";

                  return (
                    <li key={index}>
                      <div
                        className={`flex justify-between font-sans sm:text-sm text-xs  ${
                          currentDay.toLowerCase() ==
                          (langue == "en"
                            ? frToEnDays[h.Jour]
                            : h.Jour
                          ).toLowerCase()
                            ? "font-extrabold text-[13.4px] sm:text-[15.3px]"
                            : "sm:text-sm xs:text-xs"
                        }`}
                      >
                        <div className="">
                          {langue == "en" ? frToEnDays[h.Jour] : h.Jour}
                        </div>
                        <div className="">
                          {h.Horaire == "Fermé" && langue == "en"
                            ? enFerme
                            : h.Horaire}
                        </div>
                      </div>
                    </li>
                  );
                })}
              {selectedMarker.HorairesExceptionnels && (
                <li>
                  <div className="flex justify-between font-sans text-xs sm:text-sm">
                    <div className="">{titreExceptionnel}</div>
                    <div className="">
                      {horairesX == "Fermé" && langue == "en"
                        ? enFerme
                        : horairesX}
                    </div>
                  </div>
                </li>
              )}
            </ul>

            {/*
            <a href="@lienDoctolib" class="cta blue">
                    <img src="/assets/images/rdv.svg" alt="">
                    @Umbraco.GetDictionaryValue("Prendre RDV", "Prendre rendez-vous")
                </a>
            }
            @if (!string.IsNullOrEmpty(laboratoire.URLResultat))
            {
                <a href="@(laboratoire.URLResultat)" target="_blank" class="cta secondary">
                    <img src="/assets/images/resultats.svg" alt="">
                    @Umbraco.GetDictionaryValue("Voir mes résultats", "Voir mes résultats")
                </a>
            */}

            <div className="grid grid-cols-1">
              <a
                href={selectedMarker.Url.replace("//", "/")}
                className="cta normal mt-4 text-sm"
              >
                <FaArrowRight className="mr-2 inline" />
                {}
                {strings.horaire_douverture_et_informations[langue]}
              </a>
              <a
                href={selectedMarker.LienRDV}
                className="cta blue normal mt-4 text-sm"
                target="_blank"
              >
                <img
                  src="/assets/images/rdv.svg"
                  className="mr-2 font-normal"
                  style={{
                    width: "18px",
                    maxHeight: "22px",
                    display: "inline",
                  }}
                  alt=""
                />
                {strings.lienRDV[langue]}
              </a>
              <a
                href={selectedMarker.LienResultats}
                className="cta secondary normal mt-4 text-sm"
              >
                <img
                  src="/assets/images/resultats.svg"
                  className="mr-2 font-normal"
                  style={{
                    width: "18px",
                    maxHeight: "22px",
                    display: "inline",
                  }}
                  alt=""
                />
                {strings.voirResultats[langue]}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
